import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ResumeServices from '../sections/services/ResumeServices';

const ServicesPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'seo.services.title' })}
        description={intl.formatMessage({ id: 'seo.services.description' })}
      />
      <ResumeServices />
    </Layout>
  );
};

export default ServicesPage;
