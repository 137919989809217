import React from 'react';

const MainFtContainer = ({ children }) => {
  return (
    <section className="max-w-screen-xl p-8 mx-auto pt-10 md:pt-20 pb-10">
      <div className="flex flex-col md:flex-row justify-between items-center">
        {children}
      </div>
    </section>
  );
};

export default MainFtContainer;
