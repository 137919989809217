import React, { useState } from 'react';
import Slider from 'react-slick';
import CardGallery from './CardGallery';
import ArrowLeftIcon from '../../images/icons/arrowLeftIcon.svg';
import ArrowRightIcon from '../../images/icons/arrowRightIcon.svg';

const GalleryContainer = ({ projects, textColor = 'text-primary-white' }) => {
  const [projectShowing, setProjectShowing] = useState(null);

  return (
    <div className="flex flex-col items-center">
      <Slider
        slidesToShow={1}
        autoplay={true}
        ref={setProjectShowing}
        arrows={false}
        className="w-11/12 md:w-8/12 lg:w-6/12"
        pauseOnHover={true}
      >
        {projects.map((project, index) => (
          <CardGallery
            key={index}
            fileNameImage={project.fileNameImage}
            altNameImage={project.service}
          />
        ))}
      </Slider>
      <div className="flex flex-row w-1/2 md:w-4/12 lg:w-2/12 justify-between mt-3">
        <ArrowLeftIcon
          className={`w-3/12 ${textColor}`}
          style={{ cursor: 'pointer' }}
          onClick={projectShowing?.slickPrev}
        />
        <ArrowRightIcon
          className={`w-3/12 ${textColor}`}
          style={{ cursor: 'pointer' }}
          onClick={projectShowing?.slickNext}
        />
      </div>
    </div>
  );
};

export default GalleryContainer;
