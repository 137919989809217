import React from 'react';
import Image from '../../Image';

const ImageColumn = ({ fileName, alt, title }) => {
  return (
    <div className="w-full max-w-md mt-16 md:mt-0 mx-auto md:max-w-none md:mx-0 md:w-6/12 flex-shrink-0 relative">
      <Image
        fileName={fileName}
        alt={alt}
        title={title}
        className="rounded-xl"
      />
    </div>
  );
};

export default ImageColumn;
