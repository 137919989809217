import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import HeaderSection from '../../components/Services/HeaderSection';
import ServicesContainer from '../../components/Services/ServicesContainer';
import GallerySection from './GallerySection';
import CertificatesSection from './CertificatesSection';

// #region Icons
import PaintIcon from '../../images/icons/paintIcon.svg';
import DryWallIcon from '../../images/icons/dryWallIcon.svg';
import AccentWallIcon from '../../images/icons/accentWallIcon.svg';
// #endregion Icons

const ResumeServices = () => {
  const intl = useIntl();
  const images = [
    {
      fileNameImage: 'dima-painter-gallery-1.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.0',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-2.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.1',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-3.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-4.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.0',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-5.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.1',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-6.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-7.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-8.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-9.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-10.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.0',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-11.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-12.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.1',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-13.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.0',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-14.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.1',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-15.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-16.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.1',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-17.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-18.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.0',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-19.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.0',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-20.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.1',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-21.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.2',
      })}`,
    },
    {
      fileNameImage: 'dima-painter-gallery-22.jpg',
      service: `Gallery: ${intl.formatMessage({
        id: 'services.gallery.content.0',
      })}`,
    },
  ];

  const services = [
    {
      name: intl.formatMessage({ id: 'services.details.0.name' }),
      description: intl.formatMessage({ id: 'services.details.0.description' }),
      icon: PaintIcon,
    },
    {
      name: intl.formatMessage({ id: 'services.details.1.name' }),
      description: intl.formatMessage({ id: 'services.details.1.description' }),
      icon: DryWallIcon,
    },
    {
      name: intl.formatMessage({ id: 'services.details.2.name' }),
      description: intl.formatMessage({ id: 'services.details.2.description' }),
      icon: AccentWallIcon,
    },
  ];

  return (
    <section className="w-full">
      <section className="max-w-screen-xl p-8 mx-auto py-10">
        <div className="flex flex-col justify-center">
          <HeaderSection />
          <ServicesContainer services={services} />
        </div>
      </section>
      <section className="w-full bg-primary-black text-primary-white">
        <GallerySection
          title={intl.formatMessage({ id: 'services.gallery.title' })}
          images={images}
        />
      </section>
      <CertificatesSection />
    </section>
  );
};

export default ResumeServices;
