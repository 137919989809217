import React from 'react';
import CardService from './CardService';

const ServicesContainer = ({ services }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 justify-between">
      {
        services.map((service) => (
          <CardService 
            key={service.name}
            title={service.name}
            description={service.description}
            Icon={service.icon}
          />
        ))
      }
    </div>
  );
};

export default ServicesContainer;
