import React from 'react';

const TextContent = ({ subheading, heading }) => {
  return (
    <>
      <h5 className="font-bold bg-primary-navy text-primary-white px-4 transform -skew-x-12 text-center uppercase">
        {subheading}
      </h5>
      <p className="mt-4 text-sm text-justify md:text-base lg:text-lg font-medium leading-relaxed text-gray-600">
        {heading}
      </p>
    </>
  );
};

export default TextContent;
