import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import MainFeature from '../../components/Features/MainFeature/MainFeature';

const CertificatesSection = () => {
  const intl = useIntl();

  return (
    <>
      <div className="max-w-screen-xl mx-auto mt-14">
        <h1 className="text-center text-3xl md:text-5xl font-extrabold text-primary-navy">
          {intl.formatMessage({ id: 'services.certificates.title' })}
        </h1>
      </div>
      <MainFeature
        heading={intl.formatMessage({
          id: 'services.certificates.content.0.heading',
        })}
        subheading={intl.formatMessage({
          id: 'services.certificates.content.0.title',
        })}
        textOnLeft={true}
        image={{
          fileName: 'MoldAssesorRemediator.jpg',
          alt: `${intl.formatMessage({
            id: 'services.certificates.content.0.title',
          })}`,
          title: `${intl.formatMessage({
            id: 'services.certificates.content.0.title',
          })}`,
        }}
      />
      <MainFeature
        heading={intl.formatMessage({
          id: 'services.certificates.content.1.heading',
        })}
        subheading={intl.formatMessage({
          id: 'services.certificates.content.1.title',
        })}
        textOnLeft={false}
        image={{
          fileName: 'MoldAssesor.jpg',
          alt: `${intl.formatMessage({
            id: 'services.certificates.content.1.title',
          })}`,
          title: `${intl.formatMessage({
            id: 'services.certificates.content.1.title',
          })}`,
        }}
      />
      <MainFeature
        heading={intl.formatMessage({
          id: 'services.certificates.content.2.heading',
        })}
        subheading={intl.formatMessage({
          id: 'services.certificates.content.2.title',
        })}
        textOnLeft={true}
        image={{
          fileName: 'MoldRemediator.jpg',
          alt: `${intl.formatMessage({
            id: 'services.certificates.content.2.title',
          })}`,
          title: `${intl.formatMessage({
            id: 'services.certificates.content.2.title',
          })}`,
        }}
      />
      <MainFeature
        heading={intl.formatMessage({
          id: 'services.certificates.content.3.heading',
        })}
        subheading={intl.formatMessage({
          id: 'services.certificates.content.3.title',
        })}
        textOnLeft={false}
        image={{
          fileName: 'InteriorSystems.jpg',
          alt: `${intl.formatMessage({
            id: 'services.certificates.content.3.title',
          })}`,
          title: `${intl.formatMessage({
            id: 'services.certificates.content.3.title',
          })}`,
        }}
      />
      <MainFeature
        heading={intl.formatMessage({
          id: 'services.certificates.content.4.heading',
        })}
        subheading={intl.formatMessage({
          id: 'services.certificates.content.4.title',
        })}
        textOnLeft={true}
        imageFileName="VenetianPlaster.jpg"
        image={{
          fileName: 'VenetianPlaster.jpg',
          alt: `${intl.formatMessage({
            id: 'services.certificates.content.4.title',
          })}`,
          title: `${intl.formatMessage({
            id: 'services.certificates.content.4.title',
          })}`,
        }}
      />
    </>
  );
};

export default CertificatesSection;
