import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

const HeaderSection = () => {
  const intl = useIntl();
  return (
    <div className="mb-6">
      <h1 className="text-center text-3xl md:text-5xl font-extrabold text-primary-navy">{intl.formatMessage({id: 'services.header'})}</h1>
    </div>
  );
};

export default HeaderSection;
