import React from 'react';
import Image from '../Image';

const CardGallery = ({ fileNameImage, altNameImage }) => {
  return (
    <Image
      fileName={fileNameImage}
      alt={altNameImage}
      title={altNameImage}
      className="rounded-2xl"
    />
  );
};

export default CardGallery;
