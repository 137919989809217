import React from 'react';
import ImageColumn from './ImageColumn';
import MainFtContainer from './MainFtContainer';
import TextColumn from './TextColumn';

const MainFeature = ({ heading, subheading, textOnLeft, image }) => {
  return (
    <MainFtContainer>
      <TextColumn
        textOnLeft={textOnLeft}
        heading={heading}
        subheading={subheading}
      />
      <ImageColumn
        fileName={image.fileName}
        alt={image.alt}
        title={image.title}
      />
    </MainFtContainer>
  );
};

export default MainFeature;
