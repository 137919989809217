import React from 'react';

const CardService = ({ Icon, title, description }) => {
  return (
    <div className="border-2 border-dashed rounded-lg border-primary-navy py-4 px-3 mb-4">
      <figure className="w-1/2 mx-auto border rounded-full border-primary-navy text-center mb-3 p-5">
        <Icon className="relative top-1/2 w-full h-1/5 fill-current text-primary-navy  align-middle" />
      </figure>
      <h4 className="text-center font-semibold text-primary-navy mb-2">
        {title}
      </h4>
      <p className="text-center">{description}</p>
    </div>
  );
};

export default CardService;
