import React from 'react';
import GalleryContainer from '../../components/Services/GalleryContainer';

const GallerySection = ({ title, images, textColor = null }) => {
  return (
    <div className="max-w-screen-xl p-8 mx-auto py-10">
      <div id="title" className="text-center mb-8">
        <h3 className={`text-xl md:text-3xl font-bold ${textColor}`}>
          {title}
        </h3>
      </div>
      <GalleryContainer projects={images} textColor={textColor} />
    </div>
  );
};

export default GallerySection;
