import React from 'react';
import TextContent from './TextContent';

const TextColumn = ({ textOnLeft = false, heading, subheading }) => {
  return (
    <div
      className={`w-full mx-auto md:max-w-none md:mx-0 md:w-6/12  ${
        textOnLeft
          ? 'md:mr-12 lg:mr-16 md:order-first'
          : 'md:ml-12 lg:ml-16 md:order-last'
      }`}
    >
      <div className="lg:py-8">
        <TextContent heading={heading} subheading={subheading} />
      </div>
    </div>
  );
};

export default TextColumn;
